import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Contact from "../components/contact"
import HeadingBanner from "../components/headingBanner"
import "../theme/app.scss"
import Jeff from "../images/team/jeff.png"
import Chad from "../images/team/chad.png"
import Ali from "../images/team/ali.png"
import Kenny from "../images/team/kenny.png"
import MembersIntro from "../components/membersIntro"

const teamData = [
  {
    name: "Jeff Baker",
    designation: "CEO",
    profile: Jeff,
    content:
      "Jeff Baker, CEO of SensusDx, has 30+ years’ experience in pharmaceuticals and diagnostics focused on chronic infectious diseases. His work has focused on HIV, liver virology, and vector borne diseases prevalent in low- and middle-income countries. His combination of experiences give him a unique perspective on holistic solutions that drive positive health outcomes.",
  },
  {
    name: "Chad Morrison",
    designation: "CFO",
    profile: Chad,
    content:
      "Chad Morrison, CFO, brings 30+ years’ experience in financial and business analysis with a specialty in lean manufacturing. Chad has worked with hundreds of companies across a wide range of industries to improve growth through delivering transformational insights. His deep financial acumen combined with strong operational experience brings a unique vision into program and process.",
  },
  {
    name: "Ali Khan",
    designation: "CIO",
    profile: Ali,
    content:
      "Ali Khan, CIO, has 20+ years’ experience in innovation and service development. He played a large role in merging clinical and data sciences to improve health management services. He has led projects that have powered the advancement of chronic and infectious disease monitoring, bio-surveillance, personalized digital services and advanced stakeholder reporting.",
  },
  {
    name: "Kenny Tan",
    designation: "Senior Director",
    profile: Kenny,
    content:
      "Kenny Tan, Senior Director, brings 20+ years of experience in life-sciences, lab and point-of-care diagnostics in infectious disease management, with a focus on HIV. His experience encompasses real-world technical expertise with key institutions, NGOs, and governments especially in HIV point-of-care diagnostic programs and management. His experience working across Asia brings insights of the countries’ environments and cultures to deliver sustainable, user & patient-centric diagnostics journeys with high impact solutions.",
  },
]
const TeamPage = () => (
  <Layout>
    <SEO title="Team" />
    <HeadingBanner title="Meet the global team" />
    <MembersIntro data={teamData} />
    <Banner />
    <Contact />
  </Layout>
)

export default TeamPage
